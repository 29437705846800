import React, { useState } from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { animated, useTransition, to } from "react-spring";
import { useTheme } from "@material-ui/core/styles";
// import { letterFrequency } from "@visx/mock-data";

const webTechnologies = [
  { name: "Core Web", frequency: 3 },
  { name: "Library", frequency: 6 },
  { name: "Runtime", frequency: 1 },
  { name: "Database", frequency: 3 },
];

const data = webTechnologies; // just for simplicity

// accessor function for data
const frequency = (d) => d.frequency;

// const getLetterFrequencyColor = scaleOrdinal({
//   domain: data.map((l) => l.name),
//   range: [
//     "rgba(255, 44,75,0.8)",
//     "rgba(144,0,255,0.8)",
//     "rgba(72,188,236,0.8)",
//     "rgba(255, 219, 83, 0.8)",
//   ],
// });

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

function Donut({
  width,
  height,
  margin = defaultMargin,
  animate = true,
  donutThickness,
}) {
  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const theme = useTheme();

  const getLetterFrequencyColor = scaleOrdinal({
    domain: data.map((l) => l.name),
    range: [
      theme.palette.background.library,
      theme.palette.background.coreWeb,
      theme.palette.background.runtime,
      theme.palette.background.database,
    ],
  });

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  return (
    <svg width={width} height={height}>
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={
            selectedBrowser
              ? data.filter(({ name }) => name === selectedBrowser)
              : data
          }
          pieValue={frequency}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie
              // passing all the props generated by VisX Pie component to AnimatedPie component
              {...pie}
              // the below props values will be provided by AnimatedPie compoenent
              animate={animate}
              getKey={(arc) => arc.data.name}
              onClickDatum={(arc) => {
                const { name } = arc.data;
                animate &&
                  setSelectedBrowser(
                    selectedBrowser && selectedBrowser === name ? null : name
                  );
              }}
              getColor={(arc) => getLetterFrequencyColor(arc.data.name)}
            />
          )}
        </Pie>
      </Group>
    </svg>
  );
}

// react-spring transition definitions

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({ animate, arcs, path, getKey, getColor, onClickDatum }) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
  });

  return (
    <>
      {transitions((props, arc) => {
        const [centroidX, centroidY] = path.centroid(arc);
        const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

        return (
          <g>
            <animated.path
              // compute interpolated path d attribute from intermediate angle values
              d={to(
                [props.startAngle, props.endAngle],
                (startAngle, endAngle) =>
                  path({
                    ...arc,
                    startAngle,
                    endAngle,
                  })
              )}
              fill={getColor(arc)}
              onClick={() => onClickDatum(arc)}
              onTouchStart={() => onClickDatum(arc)}
            />
            {hasSpaceForLabel && (
              <animated.g style={{ opacity: props.opacity }}>
                <text
                  fill="white"
                  x={centroidX}
                  y={centroidY}
                  dy=".33em"
                  fontSize={11}
                  textAnchor="middle"
                  pointerEvents="none"
                >
                  {getKey(arc)}
                </text>
              </animated.g>
            )}
          </g>
        );
      })}
    </>
  );
}

export default Donut;
